import type { Ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";
import { useTimeoutFn } from '@vueuse/core'

export function useElementViewed(target: Ref<HTMLElement>, onViewed: () => void) {
  const { start, stop } = useTimeoutFn(() => {
    onViewed();
    stopObserver();
  }, 2000, { immediate: false })

  const { stop: stopObserver } = useIntersectionObserver(
    target,
    ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        start();
      } else {
        stop();
      }
    }, { threshold: 1 },
  )
}
