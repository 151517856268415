<template lang="pug">
lot-card(
  :lot="lot"
  :by-template="isTemplateSearch"
  :selected="selected"
  :adaptive="fromCard && !isAuth"
  :show-keyword-entries="!fromCard"
  :show-unseen-button="unseenMode === 3"
  @show-entry-keywords="$emit('showEntryKeywords', lot)"
  @mark-viewed="markSeenOnButton"
  @click.exact="onLotClick"
)
  template(#checkbox)
    slot(name="checkbox")
  template(#title)
    lot-name(ref="lotName" :lot="lot" :collapse="!fromCard" :adaptive="fromCard && !isAuth")
  template(#template-info)
    template-info(v-if="lot?.tmLot?.templates" :templates="lot?.tmLot?.templates")
  template(#status)
    app-status-selector(
      v-if="isAuth && enableLotManagement && (!isTmManager || managerCompanyId)"
      :lot="lot"
      :disabled="roleReadOnly"
      @show-create-contract="$emit('showCreateContract', $event)"
    )
  template(#dates)
    application-item-dates(:application="lot")
</template>

<script lang="ts">
import type { PropType } from "vue";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";

import { defineComponent, ref, toRefs } from "vue";
import { useTenderService } from "@/use/tenderService/useTenderService";
import { useElementViewed } from "@/components/cards/useElementViewed";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { useMarkViewed } from "@/use/other/useMarkViewed";
import { storeToRefs } from "pinia";

import AppStatusSelector from "@/components/selector/tenderStatuses/AppStatusSelector.vue";
import ApplicationItemDates from "@/components/pages/applications/card/ApplicationItemDates.vue";
import LotCard from "@/components/cards/LotCard.vue";
import TemplateInfo from "@/components/mainStreamPage/lotItem/TemplateInfo.vue";
import LotName from "~/components/cards/modules/LotName.vue";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

export default defineComponent({
  name: "LotItemSearch",
  components: {
    LotName,
    TemplateInfo,
    LotCard,
    ApplicationItemDates,
    AppStatusSelector,
  },
  props: {
    lot: {
      type: Object as PropType<LotItemInterface>,
    },
    fromCard: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "showEntryKeywords",
    "showCreateContract",
    "lotClick",
    "seen",
  ],
  setup(props, context) {

    const { isTmManager, isAuth, roleReadOnly, enableLotManagement } = useUserAccess();
    const { managerCompanyId } = useSuperAccount();

    const lotName = ref();

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { unseenMode } = storeToRefs(interfaceSettingsStore);

    const { isTemplateSearch, markAsSeen: mark, checkNeedMarkViewed } = useMarkViewed();

    function markAsSeen(lot: LotItemInterface) {
      context.emit('seen', lot)
      mark(lot)
    }

    /** unseenMode === 1 */
    if (isAuth.value && unseenMode.value === 1 && !props.fromCard && checkNeedMarkViewed(props.lot)) {
      useElementViewed(lotName, () => markAsSeen(props.lot));
    }

    /** unseenMode === 2 */
    function onLotClick(e: any) {
      if (unseenMode.value === 2 && !props.fromCard && checkNeedMarkViewed(props.lot)) markAsSeen(props.lot)
      if (roleReadOnly.value) return
      if (!['a', 'button', 'input', 'path', 'svg'].includes(e.target?.localName) && !['base-input'].includes(e.target?.className)) context.emit('lotClick')
    }

    /** unseenMode === 3 */
    function markSeenOnButton() {
      if (unseenMode.value === 3 && !props.fromCard && checkNeedMarkViewed(props.lot)) markAsSeen(props.lot)
    }

    return {
      lotName,
      isAuth,
      isTmManager,
      roleReadOnly,
      managerCompanyId,
      isTemplateSearch,
      unseenMode,
      onLotClick,
      markSeenOnButton,
      enableLotManagement,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
