import { computed } from "vue";
import { useApi } from "@/use/api/useApi";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import useSearchStore from "@/stores/search/useSearchStore";
import useTemplatesStore from "@/stores/templates/useTemplatesStore";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";

export function useMarkViewed() {

  const searchStore = useSearchStore();
  const templateStore = useTemplatesStore();

  const { isTmManager } = useUserAccess();

  const isMarkedOnClick = computed(() => useInterfaceSettingsStore().unseenMode === 2)

  const isTemplateSearch = computed(
    () => searchStore.searchPageMode === 'templateMode' && !searchStore.selectedTemplatesIds.every(e => e === -2)
  )

  function checkNeedMarkViewed(lot: LotItemInterface) {
    return lot?.IsSeen === false && isTemplateSearch.value && !isTmManager.value && lot?.tmLot?.id;
  }

  function fetchViewed(lot: LotItemInterface) {
    useApi().searchTemplates.fetchMarkViewed({
      templateIds: searchStore.selectedTemplatesIds.filter((e: number) => e !== -2),
      lotIds: [lot.tmLot?.id],
    });
  }

  /** отметка лота просмотренным - отправка запроса на бек и отметка isSeen = true локально */
  function markAsSeen(lot: LotItemInterface) {
    if (lot && lot?.IsSeen === false && lot.tmLot?.id) {
      fetchViewed(lot)
      templateStore.markViewed(lot)
    }
  }

  return {
    isTemplateSearch,
    isMarkedOnClick,
    markAsSeen,
    checkNeedMarkViewed,
  }
}
